<template>
    <div class="bys-data-map" ref="survey_outer">
      <div id="bys-data-map__map"  class="bys-data-map__map">
      </div>
      <div class="bys-data-map__sidebar">
        <div class="map-sidebar">
          <div class="map-sidebar__scrollable">
            <span class="map-sidebar__byline">{{text_labels.sidebar_byline}}</span>
            <template v-if="this.active_state === 'intro'">
              <h2 class="map-sidebar__title">{{text_labels.sidebar_title}}</h2>
              <h3 class="map-sidebar__intro-text">{{text_labels.sidebar_intro_text}}</h3>
            </template>
            <div class="map-sidebar__view-types">
              <button v-on:click="active_state = 'compare'" :class="{'map-sidebar__intro-button': true, 'map-sidebar__intro-button--active': this.active_state === 'compare'}">{{text_labels.sidebar_intro_button_compare}}</button>
              <button v-on:click="active_state = 'explore'" :class="{'map-sidebar__intro-button': true, 'map-sidebar__intro-button--active': this.active_state === 'explore'}">{{text_labels.sidebar_intro_button_explore}}</button>
            </div>


            <div v-if="this.active_state === 'explore'" class="map-sidebar__district-dropdown">
              <select class="map-sidebar__district-dropdown-select" v-model="active_district">
                <option v-for="districts in county_data.districts" :value="districts.label">{{districts.label}}</option>
              </select>
            </div>

            <div class="map-sidebar__content-type-select content-type-select" v-if="['compare'].indexOf(active_state) !== -1">
              <div class="content-type-select__icons">
                <button v-on:click="active_content_type = 'data'" class="content-type content-type--data">
                  <span :class="'content-type__icon content-type__icon--' + ((active_content_type === 'data') ? 'active' : 'inactive')">
                    <span class="content-type__icon-image content-type__icon-image--inactive">
                      <img alt="Data tab icon active" :src="text_labels.content_type_select.data_icon" />
                    </span>
                    <span class="content-type__icon-image content-type__icon-image--active">
                      <img alt="Data tab icon active" :src="text_labels.content_type_select.data_icon_active" />
                    </span>
                  </span>
                  <span class="content-type__label">{{ text_labels.content_type_select.data_label }}</span>
                </button>
                <button v-on:click="active_content_type = 'summary'" class="content-type content-type--summary">
                  <span :class="'content-type__icon content-type__icon--' + ((active_content_type === 'summary') ? 'active' : 'inactive')">
                    <span class="content-type__icon-image content-type__icon-image--inactive">
                      <img alt="Summary tab icon" :src="text_labels.content_type_select.summary_icon" />
                    </span>
                    <span class="content-type__icon-image content-type__icon-image--active">
                      <img alt="Summary tab icon active" :src="text_labels.content_type_select.summary_icon_active" />
                    </span>
                  </span>
                  <span class="content-type__label">{{ text_labels.content_type_select.summary_label }}</span>
                </button>
              </div>
              <div class="content-type-select__bar-container" v-on:click="active_content_type = (active_content_type !== 'summary') ? 'summary' : 'data'">
                <span :class="{'content-type-select__bar': true, 'content-type-select__bar--left': this.active_content_type === 'data', 'content-type-select__bar--right': this.active_content_type === 'summary'}"></span>
              </div>
            </div>

            <div v-if="['explore', 'compare'].indexOf(active_state) !== -1" class="map-sidebar__content">
              <div class="map-sidebar__accordions">
                <template v-for="stat_category in county_data.districts.find(distr => distr.label === active_district).stat_categories">
                  <div class="map-sidebar__accordion accordion" :class="{'accordion--open': active_accordion === stat_category.label}">
                    <div v-on:click="set_active_accordion(stat_category.label)" class="accordion__title">
                      <span class="accordion__title-icon"><img :src="stat_category.icon" :alt="'icon for ' + stat_category.label + ' category'"/></span><span class="accordion__title-text">{{stat_category.label}}</span>
                      <span v-if="stat_category.label === active_accordion" class="accordion__title-expander accordion__title-expander--opened">–</span>
                      <span v-else class="accordion__title-expander accordion__title-expander--closed">+</span>
                    </div>
                    <transition name="vert-slide" mode="out-in">
                      <div v-if="stat_category.label === active_accordion" class="accordion__content stats">
                        <template v-if="active_state === 'explore'">
                          <table class="stats__table">
                            <template v-if="log('county_data.la_county_averages', county_data.la_county_averages) && false">test</template>
                            <thead>
                            <template v-if="config.show_la_county_avg && county_data.la_county_averages.stat_categories.findIndex(cat => cat.label === stat_category.label) !== -1">
                              <tr>
                                <th></th>
                                <th :colspan="(stat_category.value_categories) ? stat_category.value_categories.length : 1"></th>
                                <th class="county-stat-header" :colspan="(county_data.la_county_averages.stat_categories.find(cat => cat.label === stat_category.label).value_categories) ? county_data.la_county_averages.stat_categories.find(cat => cat.label === stat_category.label).value_categories.length : 1">
                                  {{ county_data.la_county_averages.short_label }}</th>
                              </tr>
                            </template>
                            <tr>
                              <th></th>
                              <th v-if="stat_category.value_categories" v-for="value_cat in stat_category.value_categories">{{value_cat.label}}</th>
                              <th v-else>{{county_data.districts.find(distr => distr.label === active_district).short_label}}</th>
                              <template v-if="config.show_la_county_avg && county_data.la_county_averages.stat_categories.findIndex(cat => cat.label === stat_category.label) !== -1">
                                <th class="county-stat-header" v-if="county_data.la_county_averages.stat_categories.find(cat => cat.label === stat_category.label).value_categories"
                                    v-for="value_cat in county_data.la_county_averages.stat_categories.find(cat => cat.label === stat_category.label).value_categories"
                                >{{value_cat.label}}</th>
                                <th v-else>{{ county_data.la_county_averages.short_label }}</th>
                              </template>
                            </tr>
                            </thead>
                            <tbody>
                            <tr class="stat" v-for="stat in stat_category.stats">
                              <td class="stat__label">{{stat.label}}<span v-if="county_data.data_labels.hasOwnProperty(stat.label)" class="stat__info">ⓘ<div class="stat__tooltip" v-html="county_data.data_labels[stat.label]"></div></span></td>
                              <td class="stat__value" v-if="stat_category.value_categories"
                                  v-for="value_cat in stat_category.value_categories"
                                  v-html="invis_pad(stat[value_cat.key])"></td>
                              <td class="stat__value" v-else v-html="invis_pad(stat.value)"></td>

                              <template v-if="config.show_la_county_avg && county_data.la_county_averages.stat_categories.findIndex(cat => cat.label === stat_category.label) !== -1">
                                <td class="stat__county_avg" v-if="county_data.la_county_averages.stat_categories.find(cat => cat.label === stat_category.label).value_categories"
                                    v-for="value_cat in county_data.la_county_averages.stat_categories.find(cat => cat.label === stat_category.label).value_categories"
                                    v-html="invis_pad(county_data.la_county_averages.stat_categories.find(cat => cat.label === stat_category.label).stats.find(st => st.label === stat.label)[value_cat.key])"></td>
                                <td class="stat__county_avg" v-else v-html="invis_pad(stat.value_avg)"></td>
                              </template>
                            </tr>
                            </tbody>
                          </table>
                          <div v-if="stat_category.footnote" class="stats__footnote">
                            {{stat_category.footnote}}
                          </div>
                        </template>
                        <template v-else-if="active_state === 'compare'">
                          <div class="stat-wrapper" v-for="stat in stat_category.stats">
                            <div class="compare-stat" :style="cssImageVars" v-on:click="set_active_stat(stat.label)"
                                 :class="{'compare-stat--active': (active_summary_item === stat.label && active_content_type === 'summary') || (active_data_stat === stat.label && active_content_type === 'data')}"
                            >{{ stat.label }}<span
                                v-if="county_data.data_labels.hasOwnProperty(stat.label)" class="stat__info">ⓘ<div
                                class="stat__tooltip" v-html="county_data.data_labels[stat.label]"></div></span></div>
                            <template v-if="config.accordion_info_inside">
                              <div v-if="active_data_stat && (active_summary_item === stat.label && active_content_type === 'summary') || (active_data_stat === stat.label && active_content_type === 'data')" class="active-stat active-stat--inside-accordion">
                                <template v-if="active_content_type === 'data'">
                                  <div class="data-graph" v-html="data_graph"></div>
                                  <div class="data-graph-legend">
                                    <div class="data-graph-legend__item"  v-for="data_bar in data_graph_legend" >
                                      <div class="data-graph-legend__bar" :style="'background-color:' + data_bar.color"></div>
                                      <div class="data-graph-legend__text" :style="'color:' + data_bar.color">{{data_bar.label}}</div>
                                    </div>
                                  </div>
                                </template>
                                <template v-if="active_content_type === 'summary'">
                                  <div class="data-summary" v-if="county_data.summaries[active_summary_item]">
                                    <div v-html="county_data.summaries[active_summary_item]"></div>
                                  </div>
                                  <div class="data-summary data-summary--missing" v-else>
                                    No summary.
                                  </div>
                                </template>
                              </div>
                            </template>
                          </div>
                        </template> <!-- active_state === 'compare' -->
                      </div>
                    </transition> <!-- accordion transition -->
                  </div>
                  <template v-if="!config.accordion_info_inside && active_state === 'compare'">
                    <div v-if="active_data_stat && active_accordion === stat_category.label" class="post-accordion-info active-stat active-stat--outside-accordion">
                      <template v-if="active_content_type === 'data'">
                        <div class="data-graph" v-html="data_graph"></div>
                        <div class="data-graph-legend">
                          <div class="data-graph-legend__item"  v-for="data_bar in data_graph_legend" >
                            <div class="data-graph-legend__bar" :style="'background-color:' + data_bar.color"></div>
                            <div class="data-graph-legend__text" :style="'color:' + data_bar.color">{{data_bar.label}}</div>
                          </div>
                        </div>
                      </template>
                      <template v-if="active_content_type === 'summary'">
                        <div class="data-summary" v-if="county_data.summaries[active_summary_item]">
                          <div v-html="county_data.summaries[active_summary_item]"></div>
                        </div>
                        <div class="data-summary data-summary--missing" v-else>
                          No summary.
                        </div>
                      </template>
                    </div>
                  </template>
                </template> <!-- each accordion -->
              </div>
            </div>

            <div v-if="['explore', 'compare'].indexOf(active_state) !== -1" class="map-sidebar__links">
              <a target="_blank" :href="config.data_download_link" class="map-sidebar__link map-sidebar__link--download">
                  <span class="map-sidebar__link-icon" :style="'width:' + text_labels.links.download_data_icon_width + 'px'">
                    <img class="map-sidebar__link-icon__default" :alt="text_labels.links.download_data" :src="text_labels.links.download_data_icon">
                    <img class="map-sidebar__link-icon__active" :alt="text_labels.links.download_data" :src="text_labels.links.download_data_icon_active">
                  </span>
                <span class="map-sidebar__link-text">
                    {{text_labels.links.download_data}}
                  </span>
              </a>
              <button v-on:click="show_glossary = true" class="map-sidebar__link map-sidebar__link--glossary">
                  <span class="map-sidebar__link-icon" :style="'width:' + text_labels.links.glossary_icon_width + 'px'">
                    <img class="map-sidebar__link-icon__default" :alt="text_labels.links.glossary" :src="text_labels.links.glossary_icon">
                    <img class="map-sidebar__link-icon__active" :alt="text_labels.links.glossary" :src="text_labels.links.glossary_icon_active">
                  </span>
                <span class="map-sidebar__link-text">
                      {{text_labels.links.glossary}}
                  </span>
              </button>
            </div>

            <transition name="fade" mode="out-in">
              <div class="map-sidebar__glossary glossary" v-if="show_glossary">
                <button class="glossary__close" v-on:click="show_glossary = false">Close</button>
                <h3 class="glossary__title">Glossary of terms</h3>
                <div class="glossary__term glossary__term--normal" v-for="term in config.glossary">
                  <span class="glossary__term-label">{{term.label}}:</span> <span class="glossary__term-definition" v-html="term.value"></span>
                </div>
                <h4>Statistical methods:</h4>
                <div class="glossary__term glossary__term--stat" v-for="(value, key) in county_data.data_labels">
                  <span class="glossary__term-label">{{key}}:</span> <span class="glossary__term-definition" v-html="value"></span>
                </div>
                <h4>Category Footnotes:</h4>
                <div class="glossary__term glossary__term--stat" v-for="stat_category in county_data.districts.find(distr => distr.label === active_district).stat_categories">
                  <span v-if="stat_category.footnote" class="glossary__term-definition" v-html="stat_category.footnote"></span>
                </div>
              </div>
            </transition>
          </div>
        </div>

      </div>
      <div class="bys-data-map__legend">
        <div class="legend-item" v-for="legend_item in legend_items">
          <div class="legend-item__swatch" :style="'background-color: ' + legend_item.color + ';'"></div>
          <div class="legend-item__label">{{legend_item.label}}</div>
        </div>
      </div>

      <div class="bys-data-map__zoom">
        <button title="zoom map out" v-on:click="leaflet_map.zoomOut()" class="zoom-button bys-data-map__zoom--out"><span class="zoom-button__focus-control">–</span></button>
        <button title="zoom map in" v-on:click="leaflet_map.zoomIn()" class="zoom-button bys-data-map__zoom--in"><span class="zoom-button__focus-control">+</span></button>
      </div>
    </div>
</template>

<script>
import config from './config.json'
import county_data from './county_data.json'
import topo_data from './school-district-outlines--all--topo.json'
import 'leaflet/dist/leaflet.css';

const L = require('leaflet');
const topojson = require('topojson');
const d3 = require('d3');

  export default {
    data: () => {
      return {
        leaflet_map: undefined,
        legend_items: [],
        text_labels: {},
        active_state: 'intro',
        active_district: '',
        county_data: {
          districts: undefined
        },
        active_content_type: '',
        show_glossary: false,
        active_accordion: false,
        active_summary_item: false,
        active_data_stat: false,
        data_graph: "",
        data_graph_legend: []
      }
    },
    components: {

      // question: question,
    },
    created() {

      this.text_labels = config.text_labels;
      this.legend_items = config.legend_items;
      this.config = config;
      this.county_data = county_data;
      this.active_district = county_data.districts[0].label;
      this.active_content_type = config.default_active_content_type;
      this.active_accordion = config.default_active_accordion;
      this.active_summary_item = config.default_active_summary_item;
      this.active_data_stat = config.default_active_data_stat;

      // this.base_api_url = config.base_api_url;
    },
    methods: {
      set_active_accordion: function(category_label) {
        if (this.active_accordion !== category_label) {
          this.active_accordion = category_label;
          this.set_active_stat(this.county_data.districts.find(distr => distr.label === this.active_district)
              .stat_categories.find(cat => cat.label === this.active_accordion).stats[0].label,
              true)
        }
        else {
          this.active_accordion = false;
          this.set_active_stat(false, true)
        }
      },
      set_active_stat: function(data_label, set_both) {
        if (typeof set_both === "undefined") {
          set_both = false;
        }
        if (this.active_content_type === "data" || set_both) {
          if (this.active_data_stat !== data_label) {
            this.active_data_stat = data_label;
          }
        }
        if (this.active_content_type === "summary" || set_both) {
          if (this.active_summary_item !== data_label) {
            this.active_summary_item = data_label;
          }
        }
      },
      invis_pad: function(data) {
        if (isNaN(parseFloat(data))) {
          return "<span class='nan'>NaN</span>";
        }

        let parsed = parseFloat(data);

        let parsed_spl = parsed.toString().split(".");
        let ret = parsed_spl[0];
        let post_decimal;

        if (parsed_spl.length > 1) {
          post_decimal = (Math.round(parseFloat('.' + parsed_spl[1]) * Math.pow(10, this.config.right_decimal_padding)) / Math.pow(10, this.config.right_decimal_padding)).toString().split('.')[1]
          ret += '.' + post_decimal;
        }
        else {
          post_decimal = '';
          ret += '<span aria-hidden="true" class="invis">.</span>';
        }

        let pad = '<span aria-hidden="true" class="invis">0</span>';
        for (let i = 0; i < this.config.right_decimal_padding - post_decimal.length; i++) {
          ret += pad;
        }
        return ret
      },
      log: function(log_stuff) {
        console.log(...arguments)
      },
      render_data_graph: function() {
        let data = []
        let demos;
        this.county_data.districts.forEach((district) => {
          district.stat_categories.forEach((stat_cat) => {
            stat_cat.stats.forEach((stat) => {
              if (stat.label === this.active_data_stat) {

                if (!demos) {
                  demos = stat_cat.value_categories;
                }

                let push_stat = Object.assign({}, stat);
                push_stat['district'] = district.short_label
                // % values for Health & Neighborhood Conditions are not 0-1, but 0-100.
                // Change them to 0-1 to make it easier for d3.
                if (stat_cat.label === "Health & Neighborhood Conditions") {
                  demos.map(demo => demo.key).forEach((demo_key) => {
                    push_stat[demo_key] = push_stat[demo_key] / 100
                  })
                }
                data.push(push_stat)
              }
            })
          })
        })

        this.data_graph_legend = demos.map(demo => {
          if (demo.key === 'value_black') {
            return {
              'color': config.graph_colors.color_value_black,
              'label': demo.label,
              'key': demo.key,
              'text_color': config.graph_colors.text_color_value_black,
            }
          }
          else {
            return {
              'color': config.graph_colors.color_value_other,
              'label': demo.label,
              'key': demo.key,
              'text_color': config.graph_colors.text_color_value_other,
            }
          }
        })

        let margin = {top: 24, right: 27, bottom: 20, left: 100},
            width = 400 - margin.left - margin.right,
            height = 600 - margin.top - margin.bottom;

        let graph_holder = document.createElement('div');
        graph_holder.classList.add('d3-graph');
        let graph_svg = d3.select(graph_holder).append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)

        let g = graph_svg.append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        // Add axes

        let y0 = d3.scaleBand()
            .domain(data.map((d) => { return d.district; }))
            .rangeRound([0, height])
            .paddingInner(0.3);

        let y1 = d3.scaleBand() // inside each group
            .domain(demos.map(demo => demo.key))
            .padding(0)
            .rangeRound([0, y0.bandwidth()]);

        let x = d3.scaleLinear()
            .domain([0, 1]).nice()
            .range([0, width]);

        let y_axis = g.append("g")
            .attr("class", "axis axis--y")
            .attr("transform", "translate(0 0)")
            .call(d3.axisLeft(y0));

        y_axis.selectAll('path.domain')
            .attr('opacity', '0')
        y_axis.selectAll('g.tick')
            .select('line')
            .attr('opacity', '0')
        y_axis.selectAll('g.tick')
            .select('text')
            .attr('font-size', '14px')
            .attr('font-family', 'Montserrat')

        let x_axis = g.append("g")
            .attr("class", "axis axis--x")
            .call(d3.axisTop(x).ticks(6, '%'));
        x_axis.selectAll("text")
            .attr("transform", "rotate(-15)")
            .attr("text-anchor", "start");
        x_axis.selectAll('g.tick')
            .select('line')
            .attr('y2', height)
            .attr('color', config.graph_colors.vert_line_color)
        x_axis.selectAll('path.domain')
            .attr('opacity', '0')

        // Add data

        let get_color = (d) => {
          return this.data_graph_legend.find(dl => dl.key === d.key).color
        }
        let format_percentage = (val) => {
          if (val < 1) {
            val = val * 100;
          }
          // round to 2 decimal places
          val = Math.round(val * 100) / 100;
          return val + "%"
        }

        let get_text_color = (d) => {
          let text_width = get_width_of_text(format_percentage(d.value))
          let bar_width = x(d.value)
          if (bar_width > text_width + bar_data_padding * 2) {
            return this.data_graph_legend.find(dl => dl.key === d.key).text_color;
          }
          else {
            return this.data_graph_legend.find(dl => dl.key === d.key).color;
          }
        }

        let bar_data_padding = 2; // space between end of bar and text;

        let get_text_x_pos = (d) => {
          let text_width = get_width_of_text(format_percentage(d.value))
          let bar_width = x(d.value)
          if (bar_width > text_width + bar_data_padding * 2) {
            return bar_width - text_width - bar_data_padding
          }
          else {
            return bar_width + bar_data_padding
          }
        }

        let get_width_of_text = (text) => {
          let tmp = document.createElement("span");
          tmp.innerText = text;
          tmp.style.fontSize = '12px';
          tmp.style.fontWeight = 'bold';
          tmp.style.fontFamily = 'Montserrat';
          tmp.style.opacity = '0';
          document.body.append(tmp)
          let element_width = tmp.getBoundingClientRect().width;
          document.body.removeChild(tmp)
          return element_width
        }

        let groups = g.append("g")
            .selectAll("g")
            .data(data)
            .enter().append("g")
            .attr("transform", (d) => "translate(0, " + y0(d.district) + ")")
            .selectAll("rect")
            .data(function(d) { return demos.map(demo => demo.key).map((key) => { return {key: key, value: d[key]}; }); })
            .enter();
        groups.append("rect")
            .attr("x", 0)
            .attr("y", (d) => y1(d.key))
            .attr("width", (d) => x(d.value))
            .attr("height", y1.bandwidth())
            .attr("fill", get_color)
        groups.append('text')
            .attr("x", get_text_x_pos)
            .attr("y", (d) => y1(d.key) + y1.bandwidth() / 2)
            .attr("dy", "0.32em")
            .attr("fill", get_text_color)
            .attr("font-weight", "bold")
            .attr("font-size", "12px")
            .attr("text-anchor", "start")
            .text((d) => format_percentage(d.value));


        let tmp = document.createElement("div");
        tmp.appendChild(graph_holder);

        this.data_graph = tmp.innerHTML;
      },
    },
    mounted() {
      let la_county_bounds = L.latLngBounds(L.latLng(34.879, -119.048), L.latLng(33.194, -116.356)); // can be -177.356 instead to center LA county.
      let bys_data_map_id = 'bys-data-map__map'

      this.leaflet_map = L.map(bys_data_map_id, {
        // preferCanvas: false,
        zoomControl: false,
        maxBounds: la_county_bounds,
        maxBoundsViscosity: 0.8,
        zoomSnap: 0.25,
        minZoom: 9,
      }).setView([34.076, -118.160], 9);

      this.leaflet_map.fitBounds(la_county_bounds, {
        paddingTopLeft: [0, 0],
        paddingbottomRight: [0, 480]
      });

      L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
        id: "map",
        subdomains: 'abcd',
        maxZoom: 19,
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>.'
      }).addTo(this.leaflet_map);

      L.TopoJSON = L.GeoJSON.extend({
        addData: function (data) {
          var geojson, key;
          if (data.type === "Topology") {
            for (key in data.objects) {
              if (data.objects.hasOwnProperty(key)) {
                geojson = topojson.feature(data, data.objects[key]);
                L.GeoJSON.prototype.addData.call(this, geojson);
              }
            }
            return this;
          }
          L.GeoJSON.prototype.addData.call(this, data);
          return this;
        }
      });

      L.topoJson = function (data, options) {
        return new L.TopoJSON(data, options);
      };


      let school_district_boundaries = L.topoJson(null, {
        onEachFeature: (feature, layer) => {
          if (feature.properties && feature.properties.NAME) {
            layer.bindPopup(feature.properties.NAME);
            layer.on('click', (e) => {
              if (this.county_data.districts.findIndex(distr => distr.label === feature.properties.NAME) !== -1)  {
                this.active_district = feature.properties.NAME;
              }
            })
          }
        },
        style: feature => {
          let style = {};
          if (this.config.focus_districts.find(fd => fd === feature.properties['NAME'])) {
            return config.legend_items.find(lg => lg.id === "focus").style;
          }

          return config.legend_items.find(lg => lg.id === "default").style;
        }
      }).addTo(this.leaflet_map);
      topo_data.objects["school-district-outlines--all"].geometries = topo_data.objects["school-district-outlines--all"].geometries.filter(geo => {
        return geo.properties.district_type === 'unified' || this.config.focus_districts.find(fd => fd === geo.properties['NAME'])
      })

      let topology = topojson.presimplify(topo_data);
      topology = topojson.simplify(topology);

      school_district_boundaries.addData(topology);


      this.$watch('active_district', () => {
        this.active_state = "explore"
        school_district_boundaries.eachLayer((layer) => {
          if (this.config.focus_districts.find(fd => fd === layer.feature.properties['NAME'])) {
            layer.setStyle(config.legend_items.find(lg => lg.id === "focus").style)
          }
          if (layer.feature.properties.NAME === this.active_district) {
            layer.setStyle(config.legend_items.find(lg => lg.id === "selected").style)
          }
        })
      })

      this.$watch('active_data_stat', () => {
        this.render_data_graph()
      })
      this.$watch('active_content_type', () => {
        this.render_data_graph()
      })
      this.$watch('active_state', () => {
        if (this.active_state === 'compare') {
          this.render_data_graph()
        }
      })
      // we're changing active_data_stat whenever the active accordion changes, so we don't need to explicitly watch that.


      this.leaflet_map.scrollWheelZoom.disable();


      let scroll_tip_vanish_timeout = undefined
      let replace_leaflet_scroll_handler = (event) => {
        event.stopPropagation();
        if (event.ctrlKey === true) {
          event.preventDefault();
          this.leaflet_map.scrollWheelZoom.enable();
          this.leaflet_map._container.classList.remove('map-scroll');
          setTimeout(() => {
            this.leaflet_map.scrollWheelZoom.disable();
          }, 1000);
        } else {
          this.leaflet_map.scrollWheelZoom.disable();
          this.leaflet_map._container.classList.add('map-scroll');
          clearTimeout(scroll_tip_vanish_timeout);
          scroll_tip_vanish_timeout = setTimeout(() => {
            this.leaflet_map._container.classList.remove('map-scroll');
          }, 2000);
        }
      }

      document.getElementById(bys_data_map_id).addEventListener('DOMMouseScroll', replace_leaflet_scroll_handler);
      document.getElementById(bys_data_map_id).addEventListener('mousewheel', replace_leaflet_scroll_handler);

      let window_scroll_handler = (event) => {
        this.leaflet_map._container.classList.remove('map-scroll');
      };

      document.addEventListener('DOMMouseScroll', window_scroll_handler)
      document.addEventListener('mousewheel', window_scroll_handler)
    },
    computed: {
      cssImageVars() {
        return {
          '--bg-image-accordion-closed': "url(" + config.icons.item_closed + ")",
          '--bg-image-accordion-open': "url(" + config.icons.item_opened + ")",
        }
      }
    }
  }
</script>

<style lang="scss">

    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

    @mixin transition($transition...) {
      transition: $transition;
    }
    @mixin transition-property($property...) {
      transition-property: $property;
    }
    @mixin transition-duration($duration...) {
      transition-duration: $duration;
    }
    @mixin transition-timing-function($timing...) {
      transition-timing-function: $timing;
    }
    @mixin transition-delay($delay...) {
      transition-delay: $delay;
    }

    @mixin nt($prop: all, $time: 0.25s, $anim: ease-in-out) {
      //
      // These are seperated to allow for multiple element selection with unquote('item, item2') syntax.
      // @include nt($el: unquote('width, opacity'));
      // You may also use single selectors.
      // @include nt($el: width);
      // Or none at all
      // @include nt;
      //
      @include transition-property($prop);
      @include transition-duration($time);
      @include transition-timing-function($anim);
    }

    $dark-blue-shade: #004751;
    $primary-color-hover: #006b78;
    $primary-color: #008293;
    $light-blue-shade: #00a8bf; // used for selected items
    $map-light: rgba($light-blue-shade, 0.5);
    $grey: #555555;
    $light-grey: #cccccc;
    $text-color: #333;
    $sidebar-size: 480px;
    $sidebar-ext-padding: 32px;
    $sidebar-int-padding: 25px;

    $default-box-shadow: 1px 2px 4px rgba(0,0,0,0.5);

    .bys-data-map {
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      height: 80vh;
      min-height: 700px;
      max-height: 100vh;
      width: 100%;
      position: relative;
      color: $text-color;
      box-sizing: border-box;
      * {
        box-sizing: border-box;
      }
      h2, h3, h4, h5, h6, a, div, span, button {
        font-family: "Montserrat", sans-serif;
      }
    }


    .bys-data-map__map {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1;
    }
    .bys-data-map__sidebar {
      position: absolute;
      right: 0;
      top: 0;
      max-height: 100%;
      max-height: calc(100% - #{$sidebar-ext-padding * 2});
      width: $sidebar-size + $sidebar-ext-padding;
      margin: $sidebar-ext-padding $sidebar-ext-padding $sidebar-ext-padding 0;
      z-index: 2;
      overflow-y: scroll;
      background: white;
      box-shadow: $default-box-shadow;
    }


    .invis {
      opacity: 0;
    }
    .nan {
      text-align: center;
      opacity: 0.8;
    }
    .stat__county_avg,
    .stat__value {
      text-align: right;
      font-family: monospace;
    }
    .stats__table {
      width: 100%;
      border-collapse: collapse;
      th {
        text-align: right;
      }
      th, td {
        padding: 2px;
      }
      thead {
        th, td {
          text-align: center;
          letter-spacing: -0.9px;
        }
      }
    }
    .stats__footnote {
      padding-top: 15px;
    }

    .active-stat {
      &:after {
        content: '';
        display: table;
        clear: both;
      }
    }
    .active-stat--outside-accordion {
      padding: 0 20px;
    }


    .stat-wrapper {
      &:not(:last-child) {
        padding: 0 0 10px 0;
      }
    }
    .compare-stat {
      transition: color 0.25s ease;
      padding-left: 1.5em;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 1rem;
        height: 1rem;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: var(--bg-image-accordion-closed);
        background-position: center center;
      }
    }
    .compare-stat--active {
      font-weight: bold;
      color: $primary-color;
      &:before {
        background-image: var(--bg-image-accordion-open);
      }
    }

    .data-graph,
    .data-summary {
      padding: 10px 0;
      strong {
        font-weight: bold;
        color: $primary-color
      }
    }

    .data-summary--missing {
      opacity: 0.8;
      font-style: italic;
    }



    .bys-data-map__legend {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 300px;
      margin: 0 0 64px 32px;
      padding: 12px;
      z-index: 2;

      background: white;
      box-shadow: $default-box-shadow;
    }

    .legend-item {
      display: flex;
      font-size: 14px;
      align-items: center;
      &:not(:last-of-type) {
        margin-bottom: 6px;
      }
    }
    .legend-item__swatch {
      width: 16px;
      height: 16px;
      border: 1px solid $grey;
      margin-right: 8px;
      flex-shrink: 0;
    }

    .bys-data-map__zoom {
      position: absolute;
      z-index: 2;
      bottom: 0;
      left: 0;
      margin: 0 0 32px 32px;
      height: 22px;
      background: white;
      border-radius: 2px;
      border: 1px solid rgba(0,0,0,0.2);
      box-shadow: $default-box-shadow;
      display: flex;
      button {
        border: 0;
        margin: 0;
        padding: 0 5px;
        font-size: 18px;
        display: block;
        width: 25px;
        //height: 20px;
        line-height: 1;
        background: transparent;
        cursor: pointer;
        height: 100%;
        &:hover {
          background: rgba(0,0,0,0.1)
        }
      }

      button:first-of-type {
        border-right: 1px solid rgba(0,0,0,0.2);
      }
    }

    .zoom-button {
      position: relative;
    }
    .zoom-button__focus-control {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
    .map-sidebar {
      //background: white;
      //box-shadow: $default-box-shadow;
      //overflow-y: auto;
    }

    .map-sidebar__scrollable {
      padding: $sidebar-int-padding;
      position: relative;
    }


    .map-sidebar__byline {
      font-weight: bold;
      font-size: 16px;
      text-align: center;
      display: block;
      max-width: 250px;
      margin: 0.25rem auto 0.15rem;
    }
    .map-sidebar__title {
      font-weight: bold;
      font-size: 24px;
      text-align: center;
      margin: 0.15rem 0 0.75rem;
    }
    .map-sidebar__intro-text {
      font-size: 16px;
      text-align: center;
      font-weight: normal;
      margin: 0.25rem 0 0.75rem;
    }

    .map-sidebar__view-types {
      margin: 1rem 0 0.5rem;
    }

    .map-sidebar__intro-button {
      display: block;
      width: 100%;
      border: 1px solid $grey;
      padding: 8px 8px;
      font-size: 14px;
      background: transparent;
      cursor: pointer;
      @include nt();

      &:hover {
        background-color: $map-light;
      }

      &.map-sidebar__intro-button--active {
        background-color: $primary-color;
        color: white;
      }
      + .map-sidebar__intro-button {
        margin-top: 8px;
      }
    }

    .map-sidebar__district-dropdown-select {
      display: block;
      width: 100%;
      border: 1px solid $grey;
      padding: 8px 8px;
      font-size: 14px;
      background: transparent;
      margin: 0;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-appearance: none;
      -moz-appearance: none;
      cursor: pointer;
    }
    .map-sidebar__district-dropdown {
      position: relative;
      margin-top: 1rem;

      &:after {
        content: '\1405';
        pointer-events: none;
        position: absolute;
        display: block;
        right: 0;
        top: 50%;
        transform: translateY(-50%) rotate(90deg);
        padding: 0 8px;
        font-size: 16px;
      }
      &:focus-within:after {
        transform: translateY(-50%) rotate(-90deg);
      }
    }




    .content-type-select__icons {
      display: flex;
      align-content: center;
      .content-type {
        border: 1px solid transparent;
        background-color: transparent;
        flex: 50% 0 0;
        padding: 8px;
        display: flex;
        align-items: center;
        flex-direction: column;
        cursor: pointer;

        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none;
        &:active {

        }
      }
    }

    .content-type__icon {
      display: block;
      position: relative;
      height: 50px;
      width: 50px;

      .content-type__icon-image {
        opacity: 1;
        transition: 0.5s ease opacity;
      }
    }

    .content-type__icon--inactive {
      .content-type__icon-image--active {
        opacity: 0;
      }
    }
    .content-type:hover {
      .content-type__icon-image--active {
        opacity: 1;
      }
    }

    .content-type__icon--active {
      .content-type__icon-image--inactive {
        opacity: 0;
      }
    }

    .content-type__icon-image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    $content-type-select-bar-height: 16px;

    .content-type-select__bar-container {
      border: 1px solid $grey;
      border-radius: $content-type-select-bar-height / 2;
      height: $content-type-select-bar-height;
      position: relative;
    }

    .content-type-select__bar {
      width: 50%;
      height: $content-type-select-bar-height - 4;
      margin: 1px;
      display: block;
      border-radius: $content-type-select-bar-height / 2 - 2;
      background-color: $primary-color;
      //position: absolute;
      @include nt();
    }

    .content-type-select__bar--left {
      transform: translateX(0);
    }

    .content-type-select__bar--right {
      transform: translateX(calc(100% - 2px));
    }

    .map-sidebar__accordions {

    }

    .map-sidebar__accordion {
      border: 1px solid $grey;
      margin: 12px 0;
    }


    .accordion__title {
      display: flex;
      align-items: center;
      padding: 12px;
    }
    .accordion__content {
      padding: 0 12px 12px;
    }

    .accordion__title-text {
      font-weight: bold;
      color: $primary-color;
      font-size: 16px;
    }

    .accordion__title-icon {
      width: 36px;
      display: block;
      overflow: hidden;
      max-height: 40px;
      flex: 36px 0 0;
      margin: 0 8px 0 -6px;
      img {
        max-width: 100%;
        width: 100%;
        height: auto;
      }
    }

    .accordion__title-expander {
      font-size: 30px;
      line-height: 30px;
      margin-left: auto;
      font-weight: 100;
      &.accordion__title-expander--opened {
        //font-size:
      }
    }

    .stat__info {
      padding: 6px;
      margin: -6px 0;
      font-weight: normal;
      position: relative;
      cursor: pointer;
      font-size: 12px;
      transform: translateY(-3px);
      display: inline-block;
      &:hover {
        .stat__tooltip {
          opacity: 1;
          pointer-events: initial;

        }
      }
      .stat__tooltip {
        top: 100%;
        opacity: 0;
        pointer-events: none;
        background: rgba(0,0,0,0.8);
        color: white;
        font-size: 12px;
        padding: 8px;
        border-radius: 3px;
        position: absolute;
        width: 200px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .stat__county_avg,
    .county-stat-header {
        border-left: 1px solid $light-grey;
       + .county-stat-header,
       + .stat__county_avg {
         border-left: 0;
       }
    }


    .map-sidebar__links {
      margin-top: 10px;
      display: flex;
      align-content: center;
      justify-content: center;
    }

    .map-sidebar__link {
      border: 1px solid transparent;
      background-color: transparent;
      text-decoration: none;
      color: $text-color;
      flex: 50% 0 0;
      padding: 8px;
      display: flex;
      text-align: center;
      align-items: center;
      cursor: pointer;
      flex-basis: 85px;

      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-appearance: none;
      -moz-appearance: none;
      &:active {

      }
    }
    .map-sidebar__link-icon {
      width: 35px;
      height: 35px;
      display: block;
      flex-shrink: 0;
      overflow: hidden;
      img {
        max-width: 100%;
        height: auto;
      }
    }

    .map-sidebar__link-text {
      font-size: 14px;
      margin-left: 3px;
    }

    .map-sidebar__link-icon {
      position: relative;
      .map-sidebar__link-icon__active,
      .map-sidebar__link-icon__default {

        transition: opacity 0.5s ease;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
      }
      .map-sidebar__link-icon__default {
        opacity: 1;
      }
      .map-sidebar__link-icon__active {
        opacity: 0;
      }
    }
    .map-sidebar__link:hover {
      .map-sidebar__link-icon__active {
        opacity: 1;
      }
      .map-sidebar__link-icon__default {
        opacity: 0;
      }
    }

    .map-sidebar__glossary {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      padding: $sidebar-int-padding;
      background-color: white;
      h4 {
        margin-bottom: 0.5rem;
      }
    }

    .glossary__close {
      background: none;
      cursor: pointer;
      border: 0;
      position: absolute;
      top: 0;
      right: 0;
      padding: 16px;
      margin: 0;
    }
    .glossary__term-label {
      font-weight: 600;
    }
    .glossary__title {
      margin-top: 0;
    }



    /* map animations */
    .fade-enter-to, .fade-leave {
      opacity: 1;
    }

    .fade-enter-active, .fade-leave-active {
      transition: opacity 0.5s ease;
      transition-property: opacity, height, max-height;
    }

    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
    {
      opacity: 0;
    }

    .vert-slide-enter-to, .vert-slide-leave {
      opacity: 1;
      max-height: 1000px;
    }

    .vert-slide-enter-active, .vert-slide-leave-active {
      transition: opacity 0.5s ease;
      transition-property: opacity, height, max-height;
    }

    .vert-slide-enter, .vert-slide-leave-to /* .vert-slide-leave-active below version 2.1.8 */
    {
      opacity: 0;
      max-height: 0px;
    }


    #bys-data-map__map {
      &:before,
      &:after {
        opacity: 0;
        transition: opacity 0.5s ease;
        pointer-events: none;
        position: absolute;
      }
      &:before {
        content: 'Use ctrl + scroll to zoom the map';
        top: 50%;
        left: 30%;
        z-index: 999;
        font-size: 34px;
        transform: translate(-50%, -50%);
      }
      &:after {
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        content: '';
        background: #000000;
        z-index: 999;
      }
    }


    #bys-data-map__map.map-scroll:before {
      opacity: 1;
    }
    #bys-data-map__map.map-scroll:after {
      opacity: 0.2;
    }

    .leaflet-pane > svg path.leaflet-interactive {
      transition: fill 0.25s ease;
      &:hover {
        fill: $primary-color-hover
      }
    }

    .data-graph-legend {
      //max-width: 100px;
      float: right;
      display: block;
    }
    .data-graph-legend__item {
      display: flex;
    }
    .data-graph-legend__bar {
      height: 18px;
      display: inline-block;
      width: 75px;
      flex-shrink: 0;
    }
    .data-graph-legend__text {
      line-height: 18px;
      display: inline-block;
      font-weight: bold;
      padding: 0px 4px;
    }

</style>
