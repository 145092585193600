
export const setScopeRecursively = function(targetEl, scopeId) {
  // This function will recursively travel down from the targetEl and apply the scopeId as a data attribute for CSS scoping.
  // scopeId should be a string (e.g. v-123f1123a)
  try {
    targetEl.setAttribute(`data-${scopeId}`,"")
  } catch(e) {
    console.log(e)
  }

  // Recursively call setScopeRecursively for any children.
  if (targetEl.hasChildNodes()) {
    const list = targetEl.children
    for (var item of list) {
      setScopeRecursively(item, scopeId)
    }
  }
}

export function updateScopeId(el, __, vNode) {
  const vm = vNode.context
  const scopeId = vm & vm.$options._scopeId
  if (scopeId) {
    vm.$nextTick(() => { // wait till DOM was updated
      setScopeRecursively(el, `v-${scopeId}`)
    })
  }
}

export const handleErrors = function(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}
