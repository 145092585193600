var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "survey_outer", staticClass: "bys-data-map" }, [
    _c("div", {
      staticClass: "bys-data-map__map",
      attrs: { id: "bys-data-map__map" }
    }),
    _vm._v(" "),
    _c("div", { staticClass: "bys-data-map__sidebar" }, [
      _c("div", { staticClass: "map-sidebar" }, [
        _c(
          "div",
          { staticClass: "map-sidebar__scrollable" },
          [
            _c("span", { staticClass: "map-sidebar__byline" }, [
              _vm._v(_vm._s(_vm.text_labels.sidebar_byline))
            ]),
            _vm._v(" "),
            this.active_state === "intro"
              ? [
                  _c("h2", { staticClass: "map-sidebar__title" }, [
                    _vm._v(_vm._s(_vm.text_labels.sidebar_title))
                  ]),
                  _vm._v(" "),
                  _c("h3", { staticClass: "map-sidebar__intro-text" }, [
                    _vm._v(_vm._s(_vm.text_labels.sidebar_intro_text))
                  ])
                ]
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "map-sidebar__view-types" }, [
              _c(
                "button",
                {
                  class: {
                    "map-sidebar__intro-button": true,
                    "map-sidebar__intro-button--active":
                      this.active_state === "compare"
                  },
                  on: {
                    click: function($event) {
                      _vm.active_state = "compare"
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.text_labels.sidebar_intro_button_compare))]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  class: {
                    "map-sidebar__intro-button": true,
                    "map-sidebar__intro-button--active":
                      this.active_state === "explore"
                  },
                  on: {
                    click: function($event) {
                      _vm.active_state = "explore"
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.text_labels.sidebar_intro_button_explore))]
              )
            ]),
            _vm._v(" "),
            this.active_state === "explore"
              ? _c("div", { staticClass: "map-sidebar__district-dropdown" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.active_district,
                          expression: "active_district"
                        }
                      ],
                      staticClass: "map-sidebar__district-dropdown-select",
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.active_district = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    _vm._l(_vm.county_data.districts, function(districts) {
                      return _c(
                        "option",
                        { domProps: { value: districts.label } },
                        [_vm._v(_vm._s(districts.label))]
                      )
                    }),
                    0
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            ["compare"].indexOf(_vm.active_state) !== -1
              ? _c(
                  "div",
                  {
                    staticClass:
                      "map-sidebar__content-type-select content-type-select"
                  },
                  [
                    _c("div", { staticClass: "content-type-select__icons" }, [
                      _c(
                        "button",
                        {
                          staticClass: "content-type content-type--data",
                          on: {
                            click: function($event) {
                              _vm.active_content_type = "data"
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              class:
                                "content-type__icon content-type__icon--" +
                                (_vm.active_content_type === "data"
                                  ? "active"
                                  : "inactive")
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "content-type__icon-image content-type__icon-image--inactive"
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      alt: "Data tab icon active",
                                      src:
                                        _vm.text_labels.content_type_select
                                          .data_icon
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "content-type__icon-image content-type__icon-image--active"
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      alt: "Data tab icon active",
                                      src:
                                        _vm.text_labels.content_type_select
                                          .data_icon_active
                                    }
                                  })
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "content-type__label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.text_labels.content_type_select.data_label
                              )
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "content-type content-type--summary",
                          on: {
                            click: function($event) {
                              _vm.active_content_type = "summary"
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              class:
                                "content-type__icon content-type__icon--" +
                                (_vm.active_content_type === "summary"
                                  ? "active"
                                  : "inactive")
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "content-type__icon-image content-type__icon-image--inactive"
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      alt: "Summary tab icon",
                                      src:
                                        _vm.text_labels.content_type_select
                                          .summary_icon
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "content-type__icon-image content-type__icon-image--active"
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      alt: "Summary tab icon active",
                                      src:
                                        _vm.text_labels.content_type_select
                                          .summary_icon_active
                                    }
                                  })
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "content-type__label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.text_labels.content_type_select
                                  .summary_label
                              )
                            )
                          ])
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "content-type-select__bar-container",
                        on: {
                          click: function($event) {
                            _vm.active_content_type =
                              _vm.active_content_type !== "summary"
                                ? "summary"
                                : "data"
                          }
                        }
                      },
                      [
                        _c("span", {
                          class: {
                            "content-type-select__bar": true,
                            "content-type-select__bar--left":
                              this.active_content_type === "data",
                            "content-type-select__bar--right":
                              this.active_content_type === "summary"
                          }
                        })
                      ]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            ["explore", "compare"].indexOf(_vm.active_state) !== -1
              ? _c("div", { staticClass: "map-sidebar__content" }, [
                  _c(
                    "div",
                    { staticClass: "map-sidebar__accordions" },
                    [
                      _vm._l(
                        _vm.county_data.districts.find(function(distr) {
                          return distr.label === _vm.active_district
                        }).stat_categories,
                        function(stat_category) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "map-sidebar__accordion accordion",
                                class: {
                                  "accordion--open":
                                    _vm.active_accordion === stat_category.label
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "accordion__title",
                                    on: {
                                      click: function($event) {
                                        return _vm.set_active_accordion(
                                          stat_category.label
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "accordion__title-icon" },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: stat_category.icon,
                                            alt:
                                              "icon for " +
                                              stat_category.label +
                                              " category"
                                          }
                                        })
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "accordion__title-text" },
                                      [_vm._v(_vm._s(stat_category.label))]
                                    ),
                                    _vm._v(" "),
                                    stat_category.label === _vm.active_accordion
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "accordion__title-expander accordion__title-expander--opened"
                                          },
                                          [_vm._v("–")]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            staticClass:
                                              "accordion__title-expander accordion__title-expander--closed"
                                          },
                                          [_vm._v("+")]
                                        )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "transition",
                                  {
                                    attrs: {
                                      name: "vert-slide",
                                      mode: "out-in"
                                    }
                                  },
                                  [
                                    stat_category.label === _vm.active_accordion
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "accordion__content stats"
                                          },
                                          [
                                            _vm.active_state === "explore"
                                              ? [
                                                  _c(
                                                    "table",
                                                    {
                                                      staticClass:
                                                        "stats__table"
                                                    },
                                                    [
                                                      _vm.log(
                                                        "county_data.la_county_averages",
                                                        _vm.county_data
                                                          .la_county_averages
                                                      ) && false
                                                        ? [_vm._v("test")]
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "thead",
                                                        [
                                                          _vm.config
                                                            .show_la_county_avg &&
                                                          _vm.county_data.la_county_averages.stat_categories.findIndex(
                                                            function(cat) {
                                                              return (
                                                                cat.label ===
                                                                stat_category.label
                                                              )
                                                            }
                                                          ) !== -1
                                                            ? [
                                                                _c("tr", [
                                                                  _c("th"),
                                                                  _vm._v(" "),
                                                                  _c("th", {
                                                                    attrs: {
                                                                      colspan: stat_category.value_categories
                                                                        ? stat_category
                                                                            .value_categories
                                                                            .length
                                                                        : 1
                                                                    }
                                                                  }),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "th",
                                                                    {
                                                                      staticClass:
                                                                        "county-stat-header",
                                                                      attrs: {
                                                                        colspan: _vm.county_data.la_county_averages.stat_categories.find(
                                                                          function(
                                                                            cat
                                                                          ) {
                                                                            return (
                                                                              cat.label ===
                                                                              stat_category.label
                                                                            )
                                                                          }
                                                                        )
                                                                          .value_categories
                                                                          ? _vm.county_data.la_county_averages.stat_categories.find(
                                                                              function(
                                                                                cat
                                                                              ) {
                                                                                return (
                                                                                  cat.label ===
                                                                                  stat_category.label
                                                                                )
                                                                              }
                                                                            )
                                                                              .value_categories
                                                                              .length
                                                                          : 1
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .county_data
                                                                              .la_county_averages
                                                                              .short_label
                                                                          )
                                                                      )
                                                                    ]
                                                                  )
                                                                ])
                                                              ]
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _c(
                                                            "tr",
                                                            [
                                                              _c("th"),
                                                              _vm._v(" "),
                                                              _vm._l(
                                                                stat_category.value_categories,
                                                                function(
                                                                  value_cat
                                                                ) {
                                                                  return stat_category.value_categories
                                                                    ? _c("th", [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            value_cat.label
                                                                          )
                                                                        )
                                                                      ])
                                                                    : _c("th", [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.county_data.districts.find(
                                                                              function(
                                                                                distr
                                                                              ) {
                                                                                return (
                                                                                  distr.label ===
                                                                                  _vm.active_district
                                                                                )
                                                                              }
                                                                            )
                                                                              .short_label
                                                                          )
                                                                        )
                                                                      ])
                                                                }
                                                              ),
                                                              _vm._v(" "),
                                                              _vm.config
                                                                .show_la_county_avg &&
                                                              _vm.county_data.la_county_averages.stat_categories.findIndex(
                                                                function(cat) {
                                                                  return (
                                                                    cat.label ===
                                                                    stat_category.label
                                                                  )
                                                                }
                                                              ) !== -1
                                                                ? _vm._l(
                                                                    _vm.county_data.la_county_averages.stat_categories.find(
                                                                      function(
                                                                        cat
                                                                      ) {
                                                                        return (
                                                                          cat.label ===
                                                                          stat_category.label
                                                                        )
                                                                      }
                                                                    )
                                                                      .value_categories,
                                                                    function(
                                                                      value_cat
                                                                    ) {
                                                                      return _vm.county_data.la_county_averages.stat_categories.find(
                                                                        function(
                                                                          cat
                                                                        ) {
                                                                          return (
                                                                            cat.label ===
                                                                            stat_category.label
                                                                          )
                                                                        }
                                                                      )
                                                                        .value_categories
                                                                        ? _c(
                                                                            "th",
                                                                            {
                                                                              staticClass:
                                                                                "county-stat-header"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  value_cat.label
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _c(
                                                                            "th",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm
                                                                                    .county_data
                                                                                    .la_county_averages
                                                                                    .short_label
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                    }
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            2
                                                          )
                                                        ],
                                                        2
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "tbody",
                                                        _vm._l(
                                                          stat_category.stats,
                                                          function(stat) {
                                                            return _c(
                                                              "tr",
                                                              {
                                                                staticClass:
                                                                  "stat"
                                                              },
                                                              [
                                                                _c(
                                                                  "td",
                                                                  {
                                                                    staticClass:
                                                                      "stat__label"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        stat.label
                                                                      )
                                                                    ),
                                                                    _vm.county_data.data_labels.hasOwnProperty(
                                                                      stat.label
                                                                    )
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "stat__info"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "ⓘ"
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "stat__tooltip",
                                                                                domProps: {
                                                                                  innerHTML: _vm._s(
                                                                                    _vm
                                                                                      .county_data
                                                                                      .data_labels[
                                                                                      stat
                                                                                        .label
                                                                                    ]
                                                                                  )
                                                                                }
                                                                              }
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _vm._e()
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _vm._l(
                                                                  stat_category.value_categories,
                                                                  function(
                                                                    value_cat
                                                                  ) {
                                                                    return stat_category.value_categories
                                                                      ? _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "stat__value",
                                                                            domProps: {
                                                                              innerHTML: _vm._s(
                                                                                _vm.invis_pad(
                                                                                  stat[
                                                                                    value_cat
                                                                                      .key
                                                                                  ]
                                                                                )
                                                                              )
                                                                            }
                                                                          }
                                                                        )
                                                                      : _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "stat__value",
                                                                            domProps: {
                                                                              innerHTML: _vm._s(
                                                                                _vm.invis_pad(
                                                                                  stat.value
                                                                                )
                                                                              )
                                                                            }
                                                                          }
                                                                        )
                                                                  }
                                                                ),
                                                                _vm._v(" "),
                                                                _vm.config
                                                                  .show_la_county_avg &&
                                                                _vm.county_data.la_county_averages.stat_categories.findIndex(
                                                                  function(
                                                                    cat
                                                                  ) {
                                                                    return (
                                                                      cat.label ===
                                                                      stat_category.label
                                                                    )
                                                                  }
                                                                ) !== -1
                                                                  ? _vm._l(
                                                                      _vm.county_data.la_county_averages.stat_categories.find(
                                                                        function(
                                                                          cat
                                                                        ) {
                                                                          return (
                                                                            cat.label ===
                                                                            stat_category.label
                                                                          )
                                                                        }
                                                                      )
                                                                        .value_categories,
                                                                      function(
                                                                        value_cat
                                                                      ) {
                                                                        return _vm.county_data.la_county_averages.stat_categories.find(
                                                                          function(
                                                                            cat
                                                                          ) {
                                                                            return (
                                                                              cat.label ===
                                                                              stat_category.label
                                                                            )
                                                                          }
                                                                        )
                                                                          .value_categories
                                                                          ? _c(
                                                                              "td",
                                                                              {
                                                                                staticClass:
                                                                                  "stat__county_avg",
                                                                                domProps: {
                                                                                  innerHTML: _vm._s(
                                                                                    _vm.invis_pad(
                                                                                      _vm.county_data.la_county_averages.stat_categories
                                                                                        .find(
                                                                                          function(
                                                                                            cat
                                                                                          ) {
                                                                                            return (
                                                                                              cat.label ===
                                                                                              stat_category.label
                                                                                            )
                                                                                          }
                                                                                        )
                                                                                        .stats.find(
                                                                                          function(
                                                                                            st
                                                                                          ) {
                                                                                            return (
                                                                                              st.label ===
                                                                                              stat.label
                                                                                            )
                                                                                          }
                                                                                        )[
                                                                                        value_cat
                                                                                          .key
                                                                                      ]
                                                                                    )
                                                                                  )
                                                                                }
                                                                              }
                                                                            )
                                                                          : _c(
                                                                              "td",
                                                                              {
                                                                                staticClass:
                                                                                  "stat__county_avg",
                                                                                domProps: {
                                                                                  innerHTML: _vm._s(
                                                                                    _vm.invis_pad(
                                                                                      stat.value_avg
                                                                                    )
                                                                                  )
                                                                                }
                                                                              }
                                                                            )
                                                                      }
                                                                    )
                                                                  : _vm._e()
                                                              ],
                                                              2
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      )
                                                    ],
                                                    2
                                                  ),
                                                  _vm._v(" "),
                                                  stat_category.footnote
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "stats__footnote"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                stat_category.footnote
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              : _vm.active_state === "compare"
                                              ? _vm._l(
                                                  stat_category.stats,
                                                  function(stat) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "stat-wrapper"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "compare-stat",
                                                            class: {
                                                              "compare-stat--active":
                                                                (_vm.active_summary_item ===
                                                                  stat.label &&
                                                                  _vm.active_content_type ===
                                                                    "summary") ||
                                                                (_vm.active_data_stat ===
                                                                  stat.label &&
                                                                  _vm.active_content_type ===
                                                                    "data")
                                                            },
                                                            style:
                                                              _vm.cssImageVars,
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.set_active_stat(
                                                                  stat.label
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(stat.label)
                                                            ),
                                                            _vm.county_data.data_labels.hasOwnProperty(
                                                              stat.label
                                                            )
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "stat__info"
                                                                  },
                                                                  [
                                                                    _vm._v("ⓘ"),
                                                                    _c("div", {
                                                                      staticClass:
                                                                        "stat__tooltip",
                                                                      domProps: {
                                                                        innerHTML: _vm._s(
                                                                          _vm
                                                                            .county_data
                                                                            .data_labels[
                                                                            stat
                                                                              .label
                                                                          ]
                                                                        )
                                                                      }
                                                                    })
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _vm.config
                                                          .accordion_info_inside
                                                          ? [
                                                              (_vm.active_data_stat &&
                                                                _vm.active_summary_item ===
                                                                  stat.label &&
                                                                  _vm.active_content_type ===
                                                                    "summary") ||
                                                              (_vm.active_data_stat ===
                                                                stat.label &&
                                                                _vm.active_content_type ===
                                                                  "data")
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "active-stat active-stat--inside-accordion"
                                                                    },
                                                                    [
                                                                      _vm.active_content_type ===
                                                                      "data"
                                                                        ? [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "data-graph",
                                                                                domProps: {
                                                                                  innerHTML: _vm._s(
                                                                                    _vm.data_graph
                                                                                  )
                                                                                }
                                                                              }
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "data-graph-legend"
                                                                              },
                                                                              _vm._l(
                                                                                _vm.data_graph_legend,
                                                                                function(
                                                                                  data_bar
                                                                                ) {
                                                                                  return _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "data-graph-legend__item"
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "data-graph-legend__bar",
                                                                                          style:
                                                                                            "background-color:" +
                                                                                            data_bar.color
                                                                                        }
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "data-graph-legend__text",
                                                                                          style:
                                                                                            "color:" +
                                                                                            data_bar.color
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              data_bar.label
                                                                                            )
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                }
                                                                              ),
                                                                              0
                                                                            )
                                                                          ]
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _vm.active_content_type ===
                                                                      "summary"
                                                                        ? [
                                                                            _vm
                                                                              .county_data
                                                                              .summaries[
                                                                              _vm
                                                                                .active_summary_item
                                                                            ]
                                                                              ? _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "data-summary"
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        domProps: {
                                                                                          innerHTML: _vm._s(
                                                                                            _vm
                                                                                              .county_data
                                                                                              .summaries[
                                                                                              _vm
                                                                                                .active_summary_item
                                                                                            ]
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              : _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "data-summary data-summary--missing"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                No summary.\n                              "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                          ]
                                                                        : _vm._e()
                                                                    ],
                                                                    2
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          : _vm._e()
                                                      ],
                                                      2
                                                    )
                                                  }
                                                )
                                              : _vm._e()
                                          ],
                                          2
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            !_vm.config.accordion_info_inside &&
                            _vm.active_state === "compare"
                              ? [
                                  _vm.active_data_stat &&
                                  _vm.active_accordion === stat_category.label
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "post-accordion-info active-stat active-stat--outside-accordion"
                                        },
                                        [
                                          _vm.active_content_type === "data"
                                            ? [
                                                _c("div", {
                                                  staticClass: "data-graph",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.data_graph
                                                    )
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "data-graph-legend"
                                                  },
                                                  _vm._l(
                                                    _vm.data_graph_legend,
                                                    function(data_bar) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "data-graph-legend__item"
                                                        },
                                                        [
                                                          _c("div", {
                                                            staticClass:
                                                              "data-graph-legend__bar",
                                                            style:
                                                              "background-color:" +
                                                              data_bar.color
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "data-graph-legend__text",
                                                              style:
                                                                "color:" +
                                                                data_bar.color
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  data_bar.label
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              ]
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.active_content_type === "summary"
                                            ? [
                                                _vm.county_data.summaries[
                                                  _vm.active_summary_item
                                                ]
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "data-summary"
                                                      },
                                                      [
                                                        _c("div", {
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              _vm.county_data
                                                                .summaries[
                                                                _vm
                                                                  .active_summary_item
                                                              ]
                                                            )
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  : _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "data-summary data-summary--missing"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      No summary.\n                    "
                                                        )
                                                      ]
                                                    )
                                              ]
                                            : _vm._e()
                                        ],
                                        2
                                      )
                                    : _vm._e()
                                ]
                              : _vm._e()
                          ]
                        }
                      )
                    ],
                    2
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            ["explore", "compare"].indexOf(_vm.active_state) !== -1
              ? _c("div", { staticClass: "map-sidebar__links" }, [
                  _c(
                    "a",
                    {
                      staticClass:
                        "map-sidebar__link map-sidebar__link--download",
                      attrs: {
                        target: "_blank",
                        href: _vm.config.data_download_link
                      }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "map-sidebar__link-icon",
                          style:
                            "width:" +
                            _vm.text_labels.links.download_data_icon_width +
                            "px"
                        },
                        [
                          _c("img", {
                            staticClass: "map-sidebar__link-icon__default",
                            attrs: {
                              alt: _vm.text_labels.links.download_data,
                              src: _vm.text_labels.links.download_data_icon
                            }
                          }),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "map-sidebar__link-icon__active",
                            attrs: {
                              alt: _vm.text_labels.links.download_data,
                              src:
                                _vm.text_labels.links.download_data_icon_active
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "map-sidebar__link-text" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.text_labels.links.download_data) +
                            "\n              "
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "map-sidebar__link map-sidebar__link--glossary",
                      on: {
                        click: function($event) {
                          _vm.show_glossary = true
                        }
                      }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "map-sidebar__link-icon",
                          style:
                            "width:" +
                            _vm.text_labels.links.glossary_icon_width +
                            "px"
                        },
                        [
                          _c("img", {
                            staticClass: "map-sidebar__link-icon__default",
                            attrs: {
                              alt: _vm.text_labels.links.glossary,
                              src: _vm.text_labels.links.glossary_icon
                            }
                          }),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "map-sidebar__link-icon__active",
                            attrs: {
                              alt: _vm.text_labels.links.glossary,
                              src: _vm.text_labels.links.glossary_icon_active
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "map-sidebar__link-text" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.text_labels.links.glossary) +
                            "\n              "
                        )
                      ])
                    ]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
              _vm.show_glossary
                ? _c(
                    "div",
                    { staticClass: "map-sidebar__glossary glossary" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "glossary__close",
                          on: {
                            click: function($event) {
                              _vm.show_glossary = false
                            }
                          }
                        },
                        [_vm._v("Close")]
                      ),
                      _vm._v(" "),
                      _c("h3", { staticClass: "glossary__title" }, [
                        _vm._v("Glossary of terms")
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.config.glossary, function(term) {
                        return _c(
                          "div",
                          {
                            staticClass: "glossary__term glossary__term--normal"
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "glossary__term-label" },
                              [_vm._v(_vm._s(term.label) + ":")]
                            ),
                            _vm._v(" "),
                            _c("span", {
                              staticClass: "glossary__term-definition",
                              domProps: { innerHTML: _vm._s(term.value) }
                            })
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c("h4", [_vm._v("Statistical methods:")]),
                      _vm._v(" "),
                      _vm._l(_vm.county_data.data_labels, function(value, key) {
                        return _c(
                          "div",
                          {
                            staticClass: "glossary__term glossary__term--stat"
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "glossary__term-label" },
                              [_vm._v(_vm._s(key) + ":")]
                            ),
                            _vm._v(" "),
                            _c("span", {
                              staticClass: "glossary__term-definition",
                              domProps: { innerHTML: _vm._s(value) }
                            })
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c("h4", [_vm._v("Category Footnotes:")]),
                      _vm._v(" "),
                      _vm._l(
                        _vm.county_data.districts.find(function(distr) {
                          return distr.label === _vm.active_district
                        }).stat_categories,
                        function(stat_category) {
                          return _c(
                            "div",
                            {
                              staticClass: "glossary__term glossary__term--stat"
                            },
                            [
                              stat_category.footnote
                                ? _c("span", {
                                    staticClass: "glossary__term-definition",
                                    domProps: {
                                      innerHTML: _vm._s(stat_category.footnote)
                                    }
                                  })
                                : _vm._e()
                            ]
                          )
                        }
                      )
                    ],
                    2
                  )
                : _vm._e()
            ])
          ],
          2
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "bys-data-map__legend" },
      _vm._l(_vm.legend_items, function(legend_item) {
        return _c("div", { staticClass: "legend-item" }, [
          _c("div", {
            staticClass: "legend-item__swatch",
            style: "background-color: " + legend_item.color + ";"
          }),
          _vm._v(" "),
          _c("div", { staticClass: "legend-item__label" }, [
            _vm._v(_vm._s(legend_item.label))
          ])
        ])
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "bys-data-map__zoom" }, [
      _c(
        "button",
        {
          staticClass: "zoom-button bys-data-map__zoom--out",
          attrs: { title: "zoom map out" },
          on: {
            click: function($event) {
              return _vm.leaflet_map.zoomOut()
            }
          }
        },
        [
          _c("span", { staticClass: "zoom-button__focus-control" }, [
            _vm._v("–")
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "zoom-button bys-data-map__zoom--in",
          attrs: { title: "zoom map in" },
          on: {
            click: function($event) {
              return _vm.leaflet_map.zoomIn()
            }
          }
        },
        [
          _c("span", { staticClass: "zoom-button__focus-control" }, [
            _vm._v("+")
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }