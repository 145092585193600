import Vue from 'vue';
import map from './map.vue';
import {updateScopeId} from "./utilities";
Vue.config.devtools = true

Vue.directive('scope', {
  bind: updateScopeId,
});

new Vue({
  el: '#vue_container',
  render: h => h(map),
});
